import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import wilson from "../images/wilson.jpg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      className="jumbotron"
      style={{
        backgroundImage: `url(${wilson})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginBottom: 0,
        minHeight: "100vh"
      }}>
      <div className="container">
        <h2>404 NOT FOUND</h2>
        <p className="lead" style={{marginBottom: "20px"}}>Looks like you might have gotten lost</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
